import React from 'react';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, PageTitle, VisibilityHidden } from '../../../../../components';
import * as Styled from './styles';
import { TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS, defaultCheckUncheckAllLabel } from '../../enums';

type CustomizeColumnsProps = {
  closeFn: () => void;
  checkFn: (column: SelectOption, checked: boolean) => void;
  checkAllColumnsFn: (checked: boolean) => void;
  columns: SelectOption[];
  reportColumns: TableColumn[];
};

const CustomizeColumnsModal = ({
  closeFn,
  checkFn,
  columns,
  checkAllColumnsFn,
  reportColumns,
}: CustomizeColumnsProps) => (
  <Styled.WrapperStyled>
    <Styled.HeaderStyled>
      <PageTitle>Customize Columns</PageTitle>

      <Styled.ButtonStyled onClick={closeFn}>
        <FontAwesomeIcon icon={faClose} />
        <VisibilityHidden>Close</VisibilityHidden>
      </Styled.ButtonStyled>
    </Styled.HeaderStyled>

    <Styled.ContentCheckboxStyled>
      <Styled.CheckboxRowStyled>
        <Checkbox
          checked={TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.length === reportColumns.length}
          onChange={(checked) => checkAllColumnsFn(checked)}
          label={defaultCheckUncheckAllLabel.label}
        />
      </Styled.CheckboxRowStyled>
    </Styled.ContentCheckboxStyled>

    <Styled.ContentStyled>
      {TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.map((column) => (
        <Styled.ColumnStyled key={column.label}>
          <Checkbox
            checked={columns.some((checkColumn) => column.value === checkColumn.value)}
            onChange={() => checkFn(column, !columns.some((checkColumn) => column.value === checkColumn.value))}
            label={column.label}
          />
        </Styled.ColumnStyled>
      ))}
    </Styled.ContentStyled>
  </Styled.WrapperStyled>
);

export default CustomizeColumnsModal;
