import { TMTotalsProcessed } from './types';

export const TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS = [
  {
    label: 'Transaction ID',
    value: 'id',
  },
  {
    label: 'Transaction Date',
    value: 'createdAt',
  },
  {
    label: 'Processed Date',
    value: 'processedDate',
  },
  {
    label: 'Transaction Status',
    value: 'transactionStatus',
  },
  {
    label: 'New Transaction',
    value: 'firstTransaction',
  },
  {
    label: 'Transaction Note',
    value: 'note',
  },
  {
    label: 'Transaction Country',
    value: 'country',
  },
  {
    label: 'Publisher Name',
    value: 'publisherName',
  },
  {
    label: 'Publisher CID',
    value: 'acid',
  },
  {
    label: 'Merchant Name',
    value: 'merchantName',
  },
  {
    label: 'Merchant ID',
    value: 'merchantId',
  },
  {
    label: 'Order ID',
    value: 'orderId',
  },
  {
    label: 'Customer ID',
    value: 'customerId',
  },
  {
    label: 'Product ID',
    value: 'productId',
  },
  {
    label: 'Publisher ID',
    value: 'publisherId',
  },
  {
    label: 'Product Name',
    value: 'productName',
  },
  {
    label: 'Ad Id',
    value: 'adId',
  },
  {
    label: 'Net Sales',
    value: 'netSale',
  },
  {
    label: 'Gross Sales',
    value: 'grossSale',
  },
  {
    label: 'Commission ID',
    value: 'commissionIds',
  },
  {
    label: 'Commission Type',
    value: 'commissionTypes',
  },
  {
    label: 'Commission',
    value: 'commissionAmount',
  },
  {
    label: 'Currency',
    value: 'currency',
  },
  {
    label: 'Referral URL',
    value: 'referralUrl',
  },
  {
    label: 'Processed by',
    value: 'processedBy',
  },
  {
    label: 'Tracking Profile URL',
    value: 'trackingProfileUrl',
  },
  {
    label: 'Tracking Profile ID',
    value: 'trackingProfileId',
  },
  {
    label: 'Click ID',
    value: 'clickId',
  },
  {
    label: 'User OS/Browser',
    value: 'osBrowser',
  },
  {
    label: 'User Agent',
    value: 'userAgent',
  },
  {
    label: 'Product Category',
    value: 'productCategory',
  },
  {
    label: 'Third Party URL',
    value: 'thirdPartyUrl',
  },
  {
    label: 'System Usage Fee',
    value: 'systemUsageFee',
  },
  {
    label: 'Partner Id',
    value: 'partnerId',
  },
];

export const DEFAULT_TRANSACTION_MASTER_REPORT_COLUMNS = [
  ...TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.slice(0, 4),
  ...TRANSACTION_MASTER_REPORT_CUSTOMIZE_COLUMNS.slice(7, 23),
];

export const defaultOption = {
  merchant: {
    label: 'All Merchants',
    value: '',
  },

  dateType: {
    label: 'Transaction Date',
    value: 'transactionDateRange',
  },

  transactionDate: {
    label: 'Transaction Date',
    value: 'transactionDateRange',
  },

  processedDate: {
    label: 'Processed Date',
    value: 'processedDateRange',
  },

  publisher: {
    label: 'All Publishers',
    value: '',
  },

  product: {
    label: 'All Products',
    value: '',
  },

  noProduct: {
    label: 'No product ID',
    value: 'No product ID',
  },

  transactionStatus: {
    label: 'All Status',
    value: '',
  },

  trackingProfile: {
    label: 'All Tracking Profiles',
    value: '',
  },

  publisherGroup: {
    label: 'All Publisher Groups',
    value: '',
  },

  ad: {
    label: 'All Ad IDs',
    value: '',
  },

  adCampaign: {
    label: 'All Ad Campaigns',
    value: '',
  },

  productCategory: {
    label: 'All Categories',
    value: '',
  },
};

export const defaultTableTotals: TMTotalsProcessed = {
  currency: ['CAD', 'USD'],
  totalCommission: [0, 0],
  totalGrossSale: [0, 0],
  totalNetSale: [0, 0],
};

export const defaultCheckUncheckAllLabel = {
  label: 'Check/Uncheck All',
  value: '',
};
