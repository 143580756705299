import { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { useTrackingSettings } from './useTrackingSettings';
import { useTransactionSettings } from './useTransactionSettings';
import { useToast, useUserInfo } from '../../../../../hooks';
import { UPDATE_PROGRAM } from '../graphql/mutations';
import { URL_STATUSES } from '../../../../../utils';
import { MANAGE_SETTINGS } from '../enum';
import { Permission } from '../../../../../entities';
import { TRANSACTION_SETTINGS } from '../Tabs/TransactionSettings/enums';

export const useManageSettings = (permissionsCodeList: string[] = []) => {
  const firstLoad = useRef(false);
  const [isTracking, setIsTracking] = useState(true);
  const trackingHook = useTrackingSettings();
  const transactionHook = useTransactionSettings();
  const userHook = useUserInfo();
  const { hookShowToast } = useToast();
  const [updateProgram] = useMutation(UPDATE_PROGRAM);

  const trackingSettingsFormatted = {
    cookieDuration: trackingHook.hookCookieDuration,
    nofollow: trackingHook.hookNoFollow,
    statsRelay: trackingHook.hookStatsRelay,
    landingPage: trackingHook.hookLandingPageURL,
    urlQueryString: trackingHook.hookQueryURL,
    thirdPartyUrl: trackingHook.hookThirdPartyTrackingURL,
    thirdPartyUrlFormat: trackingHook.hookThirdPartyURLFormat,
    thirdPartyStatus: trackingHook.hookThirdPartyStatus,
  };

  const integrationsFormatted = transactionHook.hookTransactionTypeSettingsData.map((item) => ({
    type: item.type,
    enabled: item.enabled,
    autoApproveEnabled: item.autoApproveEnabled,
    url: item.url === TRANSACTION_SETTINGS.URLS[0].value ? '' : item.url,
  }));

  const transactionSettingsFormatted = {
    integrations: integrationsFormatted,
    allowNoOrderId: transactionHook.hookAllowNoOrderId,
    allowPartnerId: transactionHook.hookAllowPartnerId,
    allowPixeltracking: transactionHook.hookAllowPixeltracking,
    allowPostback: transactionHook.hookAllowPostback,
    autoApproveHeld: transactionHook.hookAutoApproveHeld,
    autoApprovePending: transactionHook.hookAutoApprovePending,
    autoDeclineDays: Number(transactionHook.hookDaysToAutoDecline),
    currency: transactionHook.hookSelectedCurrency.value,
    tracked: transactionHook.hookTracked,
  };

  const saveChangesHandler = async () => {
    transactionHook.hookValidateCurrency(transactionHook.hookSelectedCurrency);
    /* FC-368 - the websites URLs are already validated when onBlur event is triggered for each input,
      thus it's not possible to trigger the save function without a valid URL */
    // trackingHook.hookValidateWebsite();

    if (isTracking) {
      if (
        (trackingHook.hookLandingPageValidation === URL_STATUSES.ACTIVE_WEBSITE.STATUS ||
          trackingHook.hookLandingPageValidation === URL_STATUSES.UNSAFE_WEBSITE.STATUS ||
          trackingHook.hookLandingPageValidation === URL_STATUSES.INACTIVE_WEBSITE.STATUS) &&
        (trackingHook.hookTrackingUrl === URL_STATUSES.ACTIVE_WEBSITE.STATUS ||
          trackingHook.hookTrackingUrl === URL_STATUSES.UNSAFE_WEBSITE.STATUS ||
          trackingHook.hookTrackingUrl === URL_STATUSES.INACTIVE_WEBSITE.STATUS ||
          trackingHook.hookThirdPartyTrackingURL === '' ||
          trackingHook.hookThirdPartyTrackingURL === null)
      ) {
        if (!trackingHook.isParamsDuplicatesLandingPage()) {
          const { data, errors } = await updateProgram({
            variables: {
              input: {
                id: userHook.hookWhoAmI.programId,
                trackingSettings: trackingSettingsFormatted,
              },
            },
          });

          if (data && !errors) hookShowToast(MANAGE_SETTINGS.SETTINGS_UPDATED);
        }
      }
    } else if (!isTracking) {
      if (!transactionHook.hookCurrencyError && !transactionHook.hookAutoDeclineError) {
        const { data, errors } = await updateProgram({
          variables: {
            input: {
              id: userHook.hookWhoAmI.programId,
              transactionSettings: transactionSettingsFormatted,
            },
          },
        });
        if (data && !errors) hookShowToast(MANAGE_SETTINGS.SETTINGS_UPDATED);
      }
    }
  };

  useEffect(() => {
    if (firstLoad.current) {
      saveChangesHandler();
    }
  }, [transactionHook.hookDeleteSetting]);

  useEffect(() => {
    firstLoad.current = true;
  }, []);

  return {
    hookTracking: trackingHook,
    hookTransaction: transactionHook,
    hookSaveChanges: saveChangesHandler,
    hookIsTracking: isTracking,
    hookSetIsTracking: setIsTracking,

    hookIsReadOnlyList: Permission.readOnlyPermissionsList(permissionsCodeList),
  };
};
