import { gql } from '@apollo/client';
import { TMRow } from '../../types';

export const TRANSACTION_MASTER_CSV_REPORT = gql`
  query getTransactionsCSV($input: TransactionSearchInput!) {
    transactionMasterForCSV(input: $input) {
      transactions {
        id
        transactionStatus
        orderId
        partnerId
        clickId
        fintelTag
        saleDate
        customerId
        ad {
          id
          adName
        }
        trackingProfile {
          id
        }
        product {
          id
          name
          productCategory
          thirdPartyUrl
        }
        publisher {
          id
          companyName
        }
        merchant {
          id
          companyName
        }
        commissionTypes
        commissionIds
        acid
        saleType
        netSale
        grossSale
        commissionAmount
        referralUrl
        firstTransaction
        processedBy {
          lastName
          firstName
        }
        note
        geoIp {
          country
          region
          city
        }
        trackingProfile {
          id
          primaryWebsite
        }
        currency
        systemUsageFee
        userAgent
        createdAt
      }
    }
  }
`;

export type TransactionMasterCSVInputType = {
  input: {
    merchantId?: string;
    publisherId?: string;
    transactionStatus?: string;
    isTrackerLinked: boolean;
    transactionDateRange?: { startDate: string; endDate: string };
    processedDateRange?: { startDate: string; endDate: string };
    productId?: string;
    trackingProfileId?: string;
    publisherGroupId?: string;
    adCampaignId?: string;
    adId?: string;
    sortBy?: string; // takes sort values but doesn't use them currently
    sortDirection?: 'asc' | 'desc'; // takes sort values but doesn't use them currently
    productCategory?: string;
    search?: string;
  };
};

export type TransactionMasterCSVOutputType = {
  transactionMasterForCSV: {
    transactions: TMRow[];
  };
};
