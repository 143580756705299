import { gql } from '@apollo/client';

export const GET_COMPANY = gql`
  query company($id: ID!) {
    company(id: $id) {
      id
      apiAccessKey {
        status
        apiAccessKey
      }
      program {
        id
        trackingSettings {
          landingPage
          urlQueryString
          cookieDuration
          statsRelay
          nofollow
          thirdPartyUrl
          thirdPartyUrlFormat
          thirdPartyStatus
        }
        transactionSettings {
          autoApprovePending
          autoApproveHeld
          currency
          allowNoOrderId
          allowPartnerId
          allowPixeltracking
          allowPostback
          autoDeclineDays
          tracked
          integrations {
            id
            type
            enabled
            autoApproveEnabled
            url
          }
        }
      }
    }
  }
`;
